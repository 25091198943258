import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, ListItem, ListItemText } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

interface FaqGroupItemProps {
  items: Array<{
    title: string;
    subtitle: any;
  }>;
}

const FaqGroupItem = ({ items }: FaqGroupItemProps): JSX.Element => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? null : index,
    );
  };

  return (
    <Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
            expanded={activeIndex === i}
            onChange={() => handleClick(i)}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>{item.subtitle}</AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Faq = (): JSX.Element => {
  return (
    <Box>
      <Typography
        variant={'h4'}
        data-aos={'fade-up'}
        align={'center'}
        gutterBottom
        sx={{ fontWeight: 700 }}
      >
        Some questions answered
      </Typography>
      <Box marginBottom={6} data-aos={'fade-up'}>
        <FaqGroupItem
          items={[
            {
              title: 'Is there a risk in delegating to your data provider?',
              subtitle: (
                <Typography color="text.secondary">
                  Delegating to Flare Dienst is entirely risk-free, as your
                  tokens always remain in your wallet. The only potential risk
                  is if our provider underperforms, resulting in a lower reward
                  rate. However, we're proud to report that we've been a
                  consistently strong performer over the last year on both
                  Songbird and Flare.
                </Typography>
              ),
            },
            {
              title: 'Do you run your own nodes and infrastructure?',
              subtitle: (
                <Typography color="text.secondary">
                  We take the security and control of our infrastructure very
                  seriously, which is why we run our own nodes and other
                  services in the cloud. We're currently utilizing our private
                  cloud to host some backup services, and we're exploring ways
                  to run more backup services on our private cloud. By doing so,
                  we can increase decentralization and ensure better security,
                  while maintaining more control over our infrastructure.
                </Typography>
              ),
            },
            {
              title: 'What does that mean that you verify your data sources?',
              subtitle: (
                <Typography color="text.secondary">
                  When evaluating new exchanges or data, we conduct checks, such
                  as ensuring it has sufficient liquidity, no suspicious volume
                  spikes, and no other signs of questionable activity.
                  Additionally, we thoroughly evaluate the data for consistency,
                  ensuring it's in line with other trusted sources. If we don't
                  feel confident, we don't use it.
                </Typography>
              ),
            },
            {
              title: 'What kind of safeguards have you implemented?',
              subtitle: (
                <Typography color="text.secondary">
                  We have implemented a number of safeguards to ensure the
                  reliability and uptime of our data provider.
                  <List
                    sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }}
                  >
                    <ListItem>
                      <ListItemText>
                        <b>High availability: </b>most of our services are
                        replicated and spread across multiple clouds, so if an
                        issue arises, we can ensure each service can still run.
                        Additionally, we've implemented an extensive monitoring
                        and alerting system that notifies us immediately in case
                        anything goes wrong.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <b>Data redundancy: </b>we only collect data from
                        trusted exchanges, whilst ensuring redundancy for each
                        pair. If one exchange goes down, we can still provide
                        data from other exchanges while filtering out any bad
                        data. If the algorithm can't reach consensus during an
                        epoch it won't send anything.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <b>Algorithm safeguards: </b>we use a variety of
                        safeguards in our algorithms, including filtering
                        stablecoins that depeg beyond a certain threshold. You
                        can verify this by checking our submission during the
                        FTX depeg (around 11th of November 2022) and USDC depeg
                        (around 12th of March 2023).
                      </ListItemText>
                    </ListItem>
                  </List>
                </Typography>
              ),
            },
            {
              title: 'What more can you tell about your algorithm?',
              subtitle: (
                <Typography color="text.secondary">
                  Our algorithm is proprietary and has been in development for
                  over a year. Due to the competitive nature, we can't go into
                  specifics. However, we try to stay as true to the data as
                  possible whilst remaining competitive.
                </Typography>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Faq;
