/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IllustrationAbout from 'assets/illustrations/illustrationAbout.svg';
const About = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box maxWidth={400} width={1}>
            <Box
              component={'img'}
              src={IllustrationAbout}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography
              variant={'h4'}
              data-aos={'fade-up'}
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Our story
            </Typography>
            <Typography
              component={'p'}
              color={'text.secondary'}
              data-aos={'fade-up'}
            >
              Our team at Flare Dienst consists of two members. We have a
              founder with a background in IT, specializing in data analysis,
              cloud infrastructure and who has been a blockchain and crypto
              enthusiast for many years. Our team was later joined by an
              electrical engineer with experience in network engineering and
              server building.
              <br />
              <br />
              We started submitting to Songbird in January 2022 and to Flare in
              July 2022, achieving an uptime of over 99%. Ever since, our goal
              has been to provide infrastructural services to Songbird and Flare
              networks, including a data provider (<Link href="https://dev.flare.network/ftso/overview/">FTSO v2</Link>), validator node and
              attestation provider (<Link href="https://dev.flare.network/fdc/overview">FDC</Link>).
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
